export type LocationStateModel = {
  locations: LocationVm[];
  selectedHeadLocationUuid: string;
  statistics?: StatisticsLocation[];
};
export type LocationVm = {
  uuid: string;
  name: string;
  type: LocationType;
  geoLocalisation: GeoLocation;
  enabledFeatures: Feature[];
  subLocations: LocationVm[];
};

export type GeoLocation = {
  lat: string;
  lon: string;
};

export enum LocationType {
  TPN_PARK = 'TPN_PARK',
  TPN_PARK_VEHICLE_ENTRANCE = 'TPN_PARK_VEHICLE_ENTRANCE',
}

export enum Feature {
  map = 'map',
  white_list = 'white_list',
  entrance_traffic_analysis = 'entrance_traffic_analysis',
  statistics = 'statistics',
  barrier_management = 'barrier_management',
  webcams = 'webcams',
  parking_traffic_analysis = 'parking_traffic_analysis',
}

export interface StatisticsLocation {
  uuid: string;
  name: string;
  statistics: Statistics[];
}

export interface Statistics {
  type: StatisticsType;
  data: StatisticsData;
}

export enum StatisticsType {
  CURRENT_CAPACITY = 'CURRENT_CAPACITY',
  WHITE_LIST_TRAFFIC = 'WHITE_LIST_TRAFFIC',
  TICKET_SALES = 'TICKET_SALES',
  TRAFFIC = 'TRAFFIC',
}

export interface StatisticsData {
  entries?: number;
  exits?: number;
  online?: number;
  onSite?: number;
  onlineUsed?: number;
  takenSpots?: number;
  availableSpots?: number;
  capacity?: number;
  multiDayTickets?: number;
  anyMatch?: boolean;
}

export enum SalesType {
  Online,
  Onsite,
}

export enum StatBoxType {
  Today,
  WhiteList,
  ParkingPink,
  ParkingBlue,
  Traffic,
  TicketSales,
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance, { clearToken, setToken } from '../../utils/axios';
import { fetchStructure } from '../location/operations';
import { UserModel, UserModelProfile } from './model';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { openInfoBox } from '../layout/slice';

export const setUser = createAsyncThunk('user/setUser', async (userModel: UserModel, thunkAPI) => {
  setToken(userModel.token);
  thunkAPI.dispatch(fetchStructure());
  return userModel;
});

export const cleanUser = createAsyncThunk('user/cleanUser', async () => {
  Cookies.remove('user');
  Cookies.remove('password');
  Cookies.remove('structure');
  clearToken();
});

export const passwordReminder = async (email: string) => {
  try {
    await axiosInstance.put('/v1/user/password-reset?email=' + email);
    toast.success('Link do resetu hasła został wysłany na podany adres email.', { position: 'bottom-left' });
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const passwordReset = async (password: string, token: string) => {
  try {
    const body = { newPassword: password };
    await axiosInstance.put('/v1/user/password-reset/update?token=' + token, JSON.stringify(body));
    toast.success('Nowe hasło zostało poprawnie zapisane.', { position: 'bottom-left' });
  } catch (e) {
    const typedError = e as AxiosError;
    console.log(typedError);
    toast.error(typedError?.message);
    throw e;
  }
};

export const checkToken = async (token: string): Promise<boolean> => {
  try {
    await axiosInstance.get('/v1/user/password-reset/details?token=' + token);
    return true;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const getProfile = createAsyncThunk('user/getProfile', async () => {
  try {
    const result = await axiosInstance.get<UserModel>('/v1/profile');
    return result.data;
  } catch (e) {
    console.log(e);
  }
});

export const getUserProfile = createAsyncThunk('user/getUserProfile', async () => {
  try {
    const result = await axiosInstance.get<UserModelProfile>('/v1/profile');
    return result.data;
  } catch (e) {
    console.log(e);
  }
});

export const putUserProfile = createAsyncThunk('user/putUserProfile', async (user: UserModelProfile, thunkAPI): Promise<boolean> => {
  try {
    await axiosInstance.put<UserModelProfile>('/v1/profile', user);
    thunkAPI.dispatch(openInfoBox('Dane użytkownika zostały zaapisane.'));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
});

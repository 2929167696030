import { Link, List, ListItemButton, ListItemIcon, ListItemText, styled, Theme, useMediaQuery } from '@mui/material';
import { CSSObject } from '@mui/system';
import { MenuArrow, MenuArrowMinimal, ProfileIcon } from '../components/Icons';
import MuiDrawer from '@mui/material/Drawer';
import { getLocations } from '../modules/location/selector';
import { useAppSelector } from '../utils/hooks';
import MenuElement from './MainMenu/MenuElement';
import { IconType, UserModel } from '../modules/user/model';

import './MainMenu.css';
import { getUser } from '../modules/user/user-selector';
import { Feature } from '../modules/location/model';
import { useEffect } from 'react';

const drawerWidthExpanded = 377;
const drawerWidthMinimal = 110;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidthExpanded,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  borderRight: 0,
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidthMinimal,
  borderRight: 0,
  //   width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthMinimal,
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidthExpanded,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  border: 0,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MainMenu = ({ open, setOpen }) => {
  const locations = useAppSelector((state) => getLocations(state.location));
  const userData: UserModel = useAppSelector((state) => getUser(state.user));
  const toggleDrawerOpen = () => {
    setOpen(!open);
  };
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile, setOpen]);

  return (
    <nav className="MainMenu">
      <List style={{ paddingRight: '40px' }}>
        <MenuElement name="Statystyki" isOpen={open} to="" iconType={IconType.Mountain} setOpen={setOpen}></MenuElement>
        <List className="DashboardsMenu">
          {locations?.[0]?.subLocations?.map((l) => {
            const locationUrl = 'location/' + l.uuid;
            return (
              <MenuElement
                key={l.uuid}
                name={l.name.replace('Parking ', '').replace('Wjazd na ', '')}
                isOpen={open}
                to={locationUrl}
                iconType={IconType.Mountain}
                isSubLocation={true}
                setOpen={setOpen}
              ></MenuElement>
            );
          })}
        </List>
        {userData?.enabledFeatures.includes(Feature.map) ? <MenuElement name="Mapa" isOpen={open} to="map" iconType={IconType.Map} setOpen={setOpen}></MenuElement> : null}
        {userData?.enabledFeatures.includes(Feature.webcams) ? <MenuElement name="Kamery" isOpen={open} to="cameras" iconType={IconType.Camera} setOpen={setOpen}></MenuElement> : null}
        {userData?.enabledFeatures.includes(Feature.white_list) ? <MenuElement name="Białe listy" isOpen={open} to="white_list" iconType={IconType.Car} setOpen={setOpen}></MenuElement> : null}
        {userData?.enabledFeatures.includes(Feature.parking_traffic_analysis) ? (
          <MenuElement name="Analiza ruchu" isOpen={open} to="traffic" iconType={IconType.Traffic} setOpen={setOpen}></MenuElement>
        ) : null}
        {userData?.enabledFeatures.includes(Feature.barrier_management) ? (
          <MenuElement name="Zarządzanie szlabanami" isOpen={open} to="parking_management" iconType={IconType.Management} setOpen={setOpen}></MenuElement>
        ) : null}
      </List>
      <span
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          right: '0',
          width: '30px',
          backgroundColor: '#ffffff',
        }}
      >
        <button
          className="TogleMenuButton"
          style={{
            backgroundColor: '#f8f8fa',
            position: 'absolute',
            padding: '4px 0 4px 0',
            right: '0',
            top: '380px',
            width: '35px',
            height: '30px',
            border: 'none',
          }}
          onClick={toggleDrawerOpen}
        >
          {open ? <MenuArrow /> : <MenuArrowMinimal />}
        </button>
      </span>

      {/* <List>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    component={Link}
                    to="/user"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <ProfileIcon></ProfileIcon>
                    </ListItemIcon>
                    {open && <ListItemText primary="Moje konto" />}
                  </ListItemButton>
                </List> */}
    </nav>
  );
};

export default MainMenu;

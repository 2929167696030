import Map, { Marker } from 'react-map-gl';
import FormControl from '@mui/material/FormControl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ParkingIcon, EntranceIcon, PedestrianEntranceIcon, MountainHostelIcon, CameraIconWithCircle } from '../../components/Icons';
import Tooltip from '@mui/material/Tooltip';
import './map.css';

const MapView = (state) => {
  const entries = state.entries;
  const pedestrianEntrances = state.pedestrianEntrances;
  const mountainHostels = state.mountainHostels;

  return (
    <FormControl style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }} variant="outlined">
      <Map {...state}>
        {entries.map((entry) => (
          <Marker key={entry.uuid} longitude={entry.geoLocation.lot} latitude={entry.geoLocation.lat}>
            <div className="MapTooltip">
              {getIconByEntryType(entry.type)}
              {getTooltipByEntryType(entry.type, entry.name)}
            </div>
          </Marker>
        ))}

        {pedestrianEntrances.map((pedestrianEntrance) => (
          <Marker key={pedestrianEntrance.uuid} longitude={pedestrianEntrance.longitude} latitude={pedestrianEntrance.latitude}>
            <div className="MapTooltip">
              {getIconByEntryType('TPN_PEDESTRIAN_ENTRANCE')}
              {getTooltipByEntryType('TPN_PEDESTRIAN_ENTRANCE', pedestrianEntrance.name)}
            </div>
          </Marker>
        ))}
        {mountainHostels.map((mountainHostel) => (
          <Marker key={mountainHostel.uuid} longitude={mountainHostel.longitude} latitude={mountainHostel.latitude}>
            <div className="MapTooltip">
              {getIconByEntryType('TPN_MOUNTAIN_HOSTEL')}
              {getTooltipByEntryType('TPN_MOUNTAIN_HOSTEL', mountainHostel.name)}
            </div>
          </Marker>
        ))}
      </Map>
    </FormControl>
  );
};

const getIconByEntryType = (type) => {
  switch (type) {
    case 'TPN_PARK':
      return MountainHostelIcon;

    case 'TPN_PARK_VEHICLE_ENTRANCE':
      return EntranceIcon;

    case 'TPN_PARK_PARKING':
      return ParkingIcon;

    case 'CAMERA':
      return CameraIconWithCircle;

    case 'TPN_PEDESTRIAN_ENTRANCE':
      return PedestrianEntranceIcon;
    default:
      return MountainHostelIcon;
  }
};

const getTooltipByEntryType = (type, entryName) => {
  if (type == 'CAMERA') {
    return (
      <div
        className="PinTooltip"
        style={{
          marginTop: '-100px',
          marginLeft: '30px',
          padding: '10px 10px 10px 20px',
          fontSize: '14px',
          fontFamily: 'Inter',
          lineHeight: '17px',
          fontStyle: 'normal',
          textAlign: 'left',
          color: '#ffffff',
          minWidth: '192px',
          minHeight: '28px',
          background: '#587AAC',
          borderRadius: '4px',
          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        }}
      >
        Kamera: {entryName}
      </div>
    );
  } else {
    return (
      <div
        className="PinTooltip"
        style={{
          marginTop: '-90px',
          marginLeft: '20px',
          padding: '10px 10px 10px 20px',
          fontSize: '14px',
          fontFamily: 'Inter',
          lineHeight: '17px',
          fontStyle: 'normal',
          textAlign: 'left',
          color: '#ffffff',
          minWidth: '192px',
          minHeight: '28px',
          background: '#587AAC',
          borderRadius: '4px',
          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        }}
      >
        {entryName}
      </div>
    );
  }
};

export default MapView;

import { Button, Checkbox, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getMainLocation, getSubLocations } from '../../modules/location/selector';
import { getFilters, getVehicleTypes } from '../../modules/white-list/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { openDialog, setFilters } from '../../modules/white-list/slice';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { WhiteListRequest } from '../../modules/white-list/models';
import { fetchWhiteListRows } from '../../modules/white-list/operations';
import SortColumn, { SortColumnItem } from '../../components/SortColumn/SortColumn';
import SortDirection from '../../components/SortDirection/SortDirection';
import { format, isValid } from 'date-fns';
import { updateWhiteListUrlWithFilters } from '../../utils/urlUpdater';

const allDays = [
  { label: 'poniedziałek', value: 'monday' },
  { label: 'wtorek', value: 'tuesday' },
  { label: 'środa', value: 'wednesday' },
  { label: 'czwartek', value: 'thursday' },
  { label: 'piątek', value: 'friday' },
  { label: 'sobota', value: 'saturday' },
  { label: 'niedziela', value: 'sunday' },
];

const sortItems = [
  { value: 'vehiclePlates', name: 'Numer rej.' },
  { value: 'vehicleType', name: 'Rodzaj pojazdu' },
  { value: 'description', name: 'Opis' },
  { value: 'fromDate', name: 'Data od' },
  { value: 'toDate', name: 'Data do' },
] as SortColumnItem[];

interface FiltersProps {}

const Filters = ({}: FiltersProps) => {
  const dispatch = useAppDispatch();

  const allVehicleTypes = useAppSelector((state) => getVehicleTypes(state.whiteList));
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation?.uuid));
  const filters = useAppSelector((state) => getFilters(state.whiteList));

  const [selectedLocationLocal, setSelectedLocationLocal] = useState<string>('ALL');
  const [days, setDays] = useState<string[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [hourFrom, setHourFrom] = useState<Date | null>(null);
  const [hourTo, setHourTo] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('vehiclePlates');
  const [sortOrder, setSortOder] = useState<'asc' | 'desc'>('desc');

  const handleAddNewEntry = () => {
    dispatch(openDialog());
  };

  const handleReset = async () => {
    setDateFrom(null);
    setDateTo(null);
    setSearchText('');
    setDays([]);
    setHourFrom(null);
    setHourTo(null);
    setVehicleTypes('');
    const newFilters = {
      ...filters,
      fromDateFilter: '',
      toDateFilter: '',
      platesFilter: '',
      daysOfWeekFilter: [],
      fromHourFilter: '',
      toHourFilter: '',
      vehicleTypeUuidFilter: '',
      page: 0,
    };
    return await fetchRows(newFilters);
  };

  const handleFilterChange = async (newFilters: Partial<WhiteListRequest>) => {
    const updatedFilters = { ...filters, ...newFilters, page: 0 };
    dispatch(setFilters(updatedFilters));
    updateWhiteListUrlWithFilters(updatedFilters);
    await dispatch(fetchWhiteListRows(updatedFilters));
  };

  const handleLocationChange = async (event: SelectChangeEvent) => {
    setSelectedLocationLocal(event.target.value);
    const location = event.target.value;
    await handleFilterChange({ locationUuidFilter: location });
  };

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    const platesFilter = event.target.value;
    await handleFilterChange({ platesFilter: platesFilter });
  };

  useEffect(() => {
    const initialLocation = allSubLocations[0]?.uuid || 'ALL';
    setSelectedLocationLocal(initialLocation);
  }, [allSubLocations]);

  useEffect(() => {
    if (dateFrom || dateTo) {
      handleSearchDateChange();
    }

    if (hourFrom || hourTo) {
      handleSearchHourChange();
    }
  }, [dateFrom, dateTo, hourFrom, hourTo]);

  const handleSearchDateChange = async () => {
    const fromDateFilter = (dateFrom && isValid(dateFrom) && format(dateFrom, 'yyyy-MM-dd')) || undefined;
    const toDateFilter = (dateTo && isValid(dateTo) && format(dateTo, 'yyyy-MM-dd')) || undefined;
    await handleFilterChange({ fromDateFilter: fromDateFilter, toDateFilter: toDateFilter });
  };

  const handleChangeDay = async (event: SelectChangeEvent<typeof days>) => {
    const {
      target: { value },
    } = event;

    setDays(typeof value === 'string' ? value.split(',') : value);
    const daysOfWeekFilter = typeof value === 'string' ? value.split(',') : value;
    await handleFilterChange({ daysOfWeekFilter: daysOfWeekFilter });
  };

  const handleSearchHourChange = async () => {
    const fromHourFilter = (hourFrom && isValid(hourFrom) && format(hourFrom, 'HH:mm')) || undefined;
    const toHourFilter = (hourTo && isValid(hourTo) && format(hourTo, 'HH:mm')) || undefined;
    await handleFilterChange({ fromHourFilter: fromHourFilter, toHourFilter: toHourFilter });
  };

  const handleVtChange = async (event: SelectChangeEvent<typeof vehicleTypes>) => {
    const {
      target: { value },
    } = event;
    setVehicleTypes(value);

    const vehicleTypeUuidFilter = value;

    await handleFilterChange({ vehicleTypeUuidFilter: vehicleTypeUuidFilter });
  };

  const fetchRows = async (newFilters: WhiteListRequest) => {
    dispatch(setFilters(newFilters));
    await dispatch(fetchWhiteListRows(newFilters));
  };

  const handleSortByChange = async (sortByName: string) => {
    setSortBy(sortByName);
    const newFilters = { ...filters, sortBy: sortByName, pageNo: 0 } as WhiteListRequest;

    await fetchRows(newFilters);
  };

  const handleSortOrderChange = async (sortOrder: 'asc' | 'desc') => {
    setSortOder(sortOrder);
    const newFilters = { ...filters, sortOrder: sortOrder, pageNo: 0 } as WhiteListRequest;
    await fetchRows(newFilters);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
        <Grid container sx={{ mb: 1, gap: { xs: '10px', md: '10px', lg: '5px' }, flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' } }} spacing={0}>
          <Grid item xs={12} md={12} lg={4}>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <Select inputProps={{ 'aria-label': 'Without label' }} sx={{ width: '100%', height: '53px' }} id="selected-location" onChange={handleLocationChange} value={selectedLocationLocal}>
                <MenuItem value="ALL">Wszystkie</MenuItem>
                {allSubLocations.map((l) => (
                  <MenuItem key={l.uuid} value={l.uuid}>
                    <ListItemText primary={l.name.replace('Parking ', '').replace('Wjazd na ', '')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search">Szukaj...</InputLabel>
              <OutlinedInput
                value={searchText}
                id="outlined-adornment-search"
                onChange={handleSearchChange}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                label="Szukaj"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Stack direction="row" spacing={1}>
              <SortColumn sortBy={sortBy} handleSortByChange={handleSortByChange} items={sortItems} />
              <SortDirection sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={2}>
            <Button
              onClick={handleAddNewEntry}
              sx={{
                backgroundColor: '#6868AC',
                color: 'white',
                p: 2,
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: '#5d5d9a',
                },
              }}
            >
              Dodaj nowy pojazd
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1, gap: '5px', flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
          <Grid item xs={12} md={12} lg={4}>
            <Stack direction="column">
              <Typography sx={{ pb: '5px' }}>Data</Typography>
              <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }}>
                <DatePicker
                  label="Od"
                  value={dateFrom}
                  onChange={(fromValue) => {
                    setDateFrom(fromValue);
                  }}
                ></DatePicker>
                <DatePicker
                  label="Do"
                  value={dateTo}
                  onChange={(toValue) => {
                    setDateTo(toValue);
                  }}
                ></DatePicker>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Stack direction="column">
              <Typography sx={{ pb: '5px' }}>Dni</Typography>
              <Select
                displayEmpty
                multiple
                value={days}
                onChange={handleChangeDay}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{ color: '#00000099' }}>Wybierz dni tygodnia</Typography>;
                  }
                  return selected
                    .map((value) => {
                      const day = allDays.find((day) => day.value === value);
                      return day ? day.label : '';
                    })
                    .join(', ');
                }}
              >
                {allDays.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    <Checkbox checked={days.indexOf(day.value) > -1} />
                    <ListItemText primary={day.label} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Stack direction="column">
              <Typography sx={{ pb: '5px' }}>Godziny</Typography>
              <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }}>
                <TimePicker
                  label="Od"
                  value={hourFrom}
                  onChange={(toValue) => {
                    setHourFrom(toValue);
                  }}
                  minutesStep={10}
                ></TimePicker>

                <TimePicker
                  label="Do"
                  value={hourTo}
                  onChange={(toValue) => {
                    setHourTo(toValue);
                  }}
                ></TimePicker>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Stack direction="column">
              <Typography sx={{ pb: '5px' }}>Rodzaj pojazdu</Typography>
              <Select
                value={vehicleTypes}
                onChange={handleVtChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{ color: '#00000099' }}>Wybierz rodzaj pojazdu</Typography>;
                  }
                  return allVehicleTypes.filter((x) => selected === x.uuid).map((x) => x.name);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {allVehicleTypes.map((vt) => (
                  <MenuItem key={vt.uuid} value={vt.uuid}>
                    <Radio checked={vehicleTypes.indexOf(vt.uuid) > -1} />
                    <ListItemText primary={vt.name} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={3} sx={{ alignSelf: 'end' }}>
            <Button
              sx={{
                backgroundColor: '#6868AC',
                color: 'white',
                p: 2,
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: '#5d5d9a',
                },
              }}
              onClick={handleReset}
            >
              reset
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default Filters;

import Toolbar from '@mui/material/Toolbar';
import { Button, Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from '../utils/hooks';
import { logOut } from '../modules/auth/operations';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function TopAppBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    await dispatch(logOut());
  };

  const handleUserProfile = () => {
    handleClose();
    navigate('/user');
  };

  return (
    <header className="AppBar">
      <a href="/">
        <img className="AppLogo" src="images/logo.svg" alt="Logo BergRegions" />
      </a>
      <h1 className="AppTitle">Aplikacja do zarządzania parkingami</h1>
      <Toolbar className="AppTolbar">
        <Button className="AppLogutButton">
          <img src="images/user.svg" alt="zdjęcie użytkownika" onClick={handleClick} style={{ cursor: 'pointer' }} />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleUserProfile}>Profil</MenuItem>
            <MenuItem onClick={handleLogout} style={{ color: 'red' }}>
              Wyloguj
            </MenuItem>
          </Menu>
        </Button>
      </Toolbar>
    </header>
  );
}

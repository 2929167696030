import { Box, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { CameraIcon, CarIcon, CarsIcon, CarSmallIcon, Location2Icon, LocationIcon, MountainIcon } from '../../components/Icons';
import { Link, useLocation } from 'react-router-dom';
import { IconType } from '../../modules/user/model';
import '../MainMenu.css';

interface MenuElementProps {
  isOpen: boolean;
  name: string;
  to: string;
  iconType: IconType;
  isSubLocation?: boolean;
  setOpen: (open: boolean) => void;
}

const MenuElement = ({ isOpen, name, to, iconType, isSubLocation, setOpen }: MenuElementProps) => {
  let location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  return (
    <>
      <Box sx={{ borderLeft: isActiveLink(location.pathname, to) ? 'solid 8px #3263FB' : 'solid 8px #F8F8FA' }}>
        {(!isMobile || isOpen) && (
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isOpen ? 'initial' : 'center',
              px: 2,
              mb: 1,
              mr: 1,
              ml: isSubLocation ? 2 : 1,
              backgroundColor: getBgColor(iconType),
              borderRadius: '10px',
            }}
            className="ListItemButton"
            component={Link}
            to={to}
            onClick={handleClick}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {getIcon(iconType)}
            </ListItemIcon>

            {isOpen && <ListItemText primary={name} sx={{ color: getFontColor(iconType), fontSize: isSubLocation ? '0.7rem' : '0.875rem', fontWeight: '500' }}></ListItemText>}
            {isOpen && isSubLocation && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  pl: 1,
                }}
              >
                <span onClick={(e) => openPageMap(e)}>
                  <Location2Icon viewBox="0 0 30 24" />
                </span>
              </ListItemIcon>
            )}
          </ListItemButton>
        )}
      </Box>
    </>
  );
};

const openPageMap = (e) => {
  e.preventDefault();
  window.open('/map', '_self');
};

const getIcon = (iconType) => {
  switch (iconType) {
    case IconType.Mountain:
      return <MountainIcon />;
    case IconType.Map:
      return <LocationIcon />;
    case IconType.Car:
      return <CarIcon />;
    case IconType.Camera:
      return <CameraIcon />;
    case IconType.Traffic:
      return <CarsIcon />;
    case IconType.Management:
      return <CarSmallIcon />;
    default:
      break;
  }
};
const getBgColor = (iconType) => {
  switch (iconType) {
    case IconType.Mountain:
      return '#DFE3F6';
    case IconType.Map:
      return '#CBE8D1';
    case IconType.Car:
      return '#DCDCDC';
    case IconType.Camera:
      return '#FFE1C5';
    case IconType.Traffic:
      return '#DCDCDC';
    case IconType.Management:
      return '#FFC7C7';
    default:
      break;
  }
};
const getFontColor = (iconType) => {
  switch (iconType) {
    case IconType.Mountain:
      return '#14318D';
    case IconType.Map:
      return '#395A3B';
    case IconType.Car:
      return '#292929';
    case IconType.Camera:
      return '#654128';
    case IconType.Traffic:
      return '#495784';
    case IconType.Management:
      return '#7E4343';
    default:
      break;
  }
};

const isActiveLink = (path: string, to: string): boolean => {
  if (to === path) {
    return true;
  }
  if (path.substring(1) === to) {
    return true;
  }

  return false;
};

export default MenuElement;

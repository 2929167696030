import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { getMainLocation, getSubLocations } from '../modules/location/selector';
import { WhiteListRequest } from '../modules/white-list/models';
import { fetchVehicleTypes, fetchWhiteListRows } from '../modules/white-list/operations';
import { getFilters, getOpenDialog, getVehicleTypes } from '../modules/white-list/selectors';
import { closeDialog, setFilters, setMainLocation } from '../modules/white-list/slice';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import DataGrid from './whiteList/DataGrid';
import Filters from './whiteList/Filters';
import WhiteListDialog from './whiteList/WhiteListDialog';

const WhiteList = () => {
  const dispatch = useAppDispatch();
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation?.uuid));
  const filters = useAppSelector((state) => getFilters(state.whiteList));
  const openDialog = useAppSelector((s) => getOpenDialog(s.whiteList));

  useEffect(() => {
    const initState = async () => {
      dispatch(setMainLocation(mainLocation.uuid));
      await dispatch(fetchVehicleTypes(mainLocation.uuid));
      const newFilters = {
        ...filters,
        mainLocationId: mainLocation.uuid,
        locationUuidFilter: allSubLocations && allSubLocations.length > 0 ? allSubLocations[0].uuid : '',
      } as WhiteListRequest;
      await refreshGrid(newFilters);
    };
    initState();
  }, []);

  const handleOnDialogClose = async (saved: boolean) => {
    if (saved) {
      await refreshGrid(filters);
    }

    dispatch(closeDialog());
  };

  const refreshGrid = async (newFilters: WhiteListRequest) => {
    dispatch(setFilters(newFilters));
    await dispatch(fetchWhiteListRows(newFilters));
  };

  return (
    <>
      <Stack direction={'column'} spacing={2}>
        <Typography variant="h2" component="h2">
          Białe listy
        </Typography>
        <Filters></Filters>
        <DataGrid />
      </Stack>
      <WhiteListDialog open={openDialog} headLocation={mainLocation.uuid} onClose={handleOnDialogClose} />
    </>
  );
};

export default WhiteList;

import { Feature } from '../location/model';

export interface UserStateModel {
  userModel: UserModel;
  userProfile: UserProfile;
  isLoading: boolean;
  userModelProfile: UserModelProfile;
  openDialog: boolean;
}

export interface UserModel {
  email: string;
  role: string;
  token: string;
  enabledFeatures: Feature[];
}

export interface UserModelProfile {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  enabledFeatures: Feature[];
}

export enum IconType {
  Mountain,
  Map,
  Camera,
  Car,
  Traffic,
  Management,
}

export interface UserProfile {
  enabledFeatures: Feature[];
}

import { Button, Stack, TextField } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import AuthHeaderBox from './login/AuthHeader';
import { checkToken, passwordReset } from '../modules/user/operations';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const PasswordReset = () => {
  let [searchParams] = useSearchParams();
  const [enableButton, setEnableButton] = useState<boolean>(true);

  useEffect(() => {
    const token = searchParams.get('token');
    console.log(token);
    if (token) {
      const checkTokenInner = async () => {
        const result = await checkToken(token);
        console.log(result);
        setEnableButton(result);
      };

      checkTokenInner();
      return;
    }
    setEnableButton(false);
  }, []);

  useEffect(() => {
    if (!enableButton) {
      toast.error('Nieprawodłowy token', { autoClose: false });
    }
  }, [enableButton]);

  const validatePassword = (password: string) => {
    const minLength = password.length >= 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return {
      minLength,
      hasUpperCase,
      hasSpecialChar,
      isValid: minLength && hasUpperCase && hasSpecialChar,
    };
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const token = searchParams.get('token');
    const password = event.target.password.value;
    const password2 = event.target.password2.value;

    if (token) {
      if (password !== password2) {
        toast.error('Wprowadzone hasła nie zgadzają się.');
        return;
      }
      const validation = validatePassword(password);
      if (!validation.minLength) {
        toast.error('Wprowadzone hasło powinno zawierać minimum 6 znaków.');
        return;
      }
      if (!validation.hasUpperCase) {
        toast.error('Hasło musi zawierać co najmniej jedną dużą literę.');
        return;
      }
      if (!validation.hasSpecialChar) {
        toast.error('Hasło musi zawierać co najmniej jeden znak specjalny.');
        return;
      }
      await passwordReset(password, token);
      event.target.password.value = '';
      event.target.password2.value = '';
    }
  };

  return (
    <Stack
      sx={{
        marginTop: 8,
      }}
      component="form"
      onSubmit={handleSubmit}
      noValidate
      spacing={2}
      direction={'column'}
      alignItems={'center'}
    >
      <AuthHeaderBox message="Wpisz poniżej nowe hasło, które od tej pory będzie służyło do logowania się do strony. (minimum 6 znaków, co najmniej jedna duża litera i jeden znak specjany)" />
      <TextField fullWidth id="password" label="Hasło" name="password" autoComplete="current-password" type="password" inputProps={{ minLength: 6 }} />
      <TextField fullWidth id="password2" label="Potwierdź hasło" name="password2" autoComplete="current-password" type="password" inputProps={{ minLength: 6 }} />
      <Stack direction={'row'} spacing={1}>
        <Button component={Link} to="/login" type="submit" variant="outlined" sx={{ width: '200px', height: '72px' }}>
          Wróć do logowania
        </Button>
        <Button type="submit" variant="contained" sx={{ width: '200px', height: '72px' }} disabled={!enableButton}>
          Ustaw nowe hasło
        </Button>
      </Stack>
    </Stack>
  );
};

export default PasswordReset;

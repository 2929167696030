import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { UserModel } from '../user/model';
import { cleanUser, getProfile, setUser } from '../user/operations';
import { AuthUserDataModel } from './models';

export const logIn = createAsyncThunk('auth/logIn', async (authUserData: AuthUserDataModel, thunkAPI) => {
  const data = JSON.stringify(authUserData);
  try {
    const response = await axiosInstance.post<UserModel>('/v1/auth/signin/', data);
    thunkAPI.dispatch(setUser(response.data));
    thunkAPI.dispatch(getProfile());
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
});

export const logOut = createAsyncThunk('auth/logOut', async (data: void, thunkAPI) => {
  thunkAPI.dispatch(cleanUser());
});

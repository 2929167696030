import { Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { SalesType } from '../../modules/location/model';
import { HomeIcon, WifiIcon } from '../../components/Icons';

interface TicketChartProps {
  salesType: SalesType;
  value?: number;
  sum: number;
}

const TicketChart = ({ salesType, value, sum }: TicketChartProps) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={8}>
            <Stack direction="row">
              {salesType === SalesType.Online ? <WifiIcon viewBox="0 0 32 32" sx={{ width: 32, height: 32 }} /> : <HomeIcon viewBox="0 0 32 32" sx={{ width: 32, height: 32 }} />}
              <Stack direction="column" sx={{ ml: 1 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '0.86rem' }}>{salesType === SalesType.Online ? 'E-bilety' : 'Na miejscu'}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ alignContent: 'right' }}>{value} szt.</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <LinearProgress value={getPerc(value, sum)} variant="determinate" sx={{ height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': { backgroundColor: salesType === SalesType.Online ? '#6868AC' : '#A6BBA6' } }} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
const getPerc = (value?: number, sum?: number): number => {
  if (value && sum) {
    return Math.ceil((value / sum) * 100);
  }
  return 0;
};
export default TicketChart;

import { Button, ButtonGroup } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface SortDirectionProps {
  sortOrder: 'asc' | 'desc',
  handleSortOrderChange: (sortOrder: 'asc' | 'desc') => void
}

const SortDirection = ({ handleSortOrderChange, sortOrder }: SortDirectionProps) => {
  return <ButtonGroup aria-label='outlined primary button group'>
    <Button size='small' variant={sortOrder === 'asc' ? 'contained' : 'outlined'}
            onClick={() => handleSortOrderChange('asc')}>
      <ArrowDownwardIcon></ArrowDownwardIcon>
    </Button>
    <Button size='small' variant={sortOrder === 'desc' ? 'contained' : 'outlined'}
            onClick={() => handleSortOrderChange('desc')}>
      <ArrowUpwardIcon></ArrowUpwardIcon>
    </Button>
  </ButtonGroup>;
};

export default SortDirection;
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Barrier, ScheduleData } from '../../modules/barriers/models';
import { Divider, Stack } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import plLocale from 'date-fns/locale/pl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { selectedLocationUuid } from '../../modules/barriers/selectors';
import format from 'date-fns/format';
import { scheduleBarrierOpening } from '../../modules/barriers/operations';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ScheduleDialogProps {
  open: boolean;
  barrier?: Barrier;
  onClose: () => void;
}

const ScheduleDialog = ({ open, barrier, onClose }: ScheduleDialogProps) => {
  const dispatch = useAppDispatch();
  const selectedLocation = useAppSelector((s) => selectedLocationUuid(s.barriers));
  const handleScheduleClick = () => {
    if (timeFrom && timeTo) {
      const data = {
        barrierUuid: barrier?.uuid,
        locationUuid: selectedLocation,
        fromTime: format(new Date(timeFrom), 'HH:mm:00'),
        toTime: format(new Date(timeTo), 'HH:mm:00'),
      } as ScheduleData;
      console.log(data);
      dispatch(scheduleBarrierOpening(data));
      onClose();
    }
  };

  useEffect(() => {
    setTimeFrom(new Date(getDateFromTime(barrier?.openingTime)));
    setTimeTo(new Date(getDateFromTime(barrier?.closingTime)));
  }, [barrier]);

  const [timeFrom, setTimeFrom] = useState<Date | null>();
  const [timeTo, setTimeTo] = useState<Date | null>();
  return (
    <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" sx={{ py: 2, pl: 2, pr: '45px' }}>
        Planowania otwarcia/zamkniecia szlabanu
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
            <Typography sx={{ fontWeight: 500 }}>Szlaban - {barrier?.name}</Typography>
            <Typography>Wybierz godzinę otwarcia</Typography>
            <TimePicker label="" value={timeFrom} onChange={(t) => setTimeFrom(t)} ampm={false} minutesStep={10} />
            <Typography>Wybierz godzinę zamknięcia</Typography>
            <TimePicker label="" value={timeTo} onChange={(t) => setTimeTo(t)} ampm={false} minutesStep={10} />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button autoFocus onClick={handleScheduleClick} sx={{ px: 3, backgroundColor: '#1891E5', '&:hover ': { backgroundColor: '#1582ce' }, color: 'white' }}>
          Zaplanuj
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

const getDateFromTime = (time: string | undefined): Date => {
  if (!time) {
    return new Date();
  }
  console.log(`2020-01-01T${time}`);
  return new Date(`2020-01-01T${time}`);
};

export default ScheduleDialog;

import { Button, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getMainLocation, getSubLocations } from '../../modules/location/selector';
import { DataGridFilters } from '../../modules/traffic-analisys/models';
import { fetchTrafficAnalisysRows } from '../../modules/traffic-analisys/operations';
import { getFilters } from '../../modules/traffic-analisys/selectors';
import { setFilters } from '../../modules/traffic-analisys/slice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import SearchIcon from '@mui/icons-material/Search';
import SortDirection from '../../components/SortDirection/SortDirection';
import SortColumn, { SortColumnItem } from '../../components/SortColumn/SortColumn';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { updateTrafficAnalysisUrlWithFilters } from '../../utils/urlUpdater';

const sortItems = [
  // { value: 'vehiclePlates', name: 'Numer rej' },
  // { value: 'eventType', name: 'Zdarzenie' },
  { value: 'eventTimestamp', name: 'Data i godzina' },
  // { value: 'ticketPaymentTimestamp', name: 'Data płatności' },
  // { value: 'ticketCreationTimestamp', name: 'Data zakupu' },
  // { value: 'ticketId', name: 'Numer biletu' },
  // { value: 'ticketPaymentLocationName', name: 'Miejsce zakupu' },
  // { value: 'ticketTypeName', name: 'Rodzaj biletu' },
  // { value: 'ticketPrice', name: 'Cena' }
] as SortColumnItem[];

const Filters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((s) => getFilters(s.trafficAnalysis));
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation?.uuid));

  const [selectedLocationLocal, setSelectedLocationLocal] = useState<string>(filters.locationId || 'ALL');
  const [searchText, setSearchText] = useState<string>('');
  const [sortOrder, setSortOder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState<string>('eventTimestamp');
  const [dateTimeFrom, setDateTimeFrom] = useState<Date | null>(null);
  const [dateTimeTo, setDateTimeTo] = useState<Date | null>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [whiteList, setWhiteList] = useState<boolean>(false);

  const handleFilterChange = async (newFilters: Partial<DataGridFilters>) => {
    const updatedFilters = { ...filters, ...newFilters, page: 0 };
    dispatch(setFilters(updatedFilters));
    updateTrafficAnalysisUrlWithFilters(updatedFilters);
    await dispatch(fetchTrafficAnalisysRows(updatedFilters));
  };

  const handleLocationChange = async (event: SelectChangeEvent) => {
    setSelectedLocationLocal(event.target.value);
    const locationId = event.target.value;
    await handleFilterChange({ locationId: locationId });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const newFilters = { ...filters, searchText: event.target.value, pageNo: 0 } as DataGridFilters;

    refreshGrid(newFilters);
  };

  const handleSortByChange = (sortByName: string) => {
    setSortBy(sortByName);
    const newFilters = { ...filters, sortBy: sortByName, pageNo: 0 } as DataGridFilters;

    refreshGrid(newFilters);
  };

  const handleSortOrderChange = (sortOrder: 'asc' | 'desc') => {
    setSortOder(sortOrder);
    const newFilters = { ...filters, sortOrder: sortOrder, pageNo: 0 } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const handleReset = async () => {
    setDateTimeFrom(null);
    setDateTimeTo(null);
    setSearchText('');
    setWhiteList(false);
    const newFilters = {
      ...filters,
      eventTimestampFrom: '',
      eventTimestampTo: '',
      whiteList: false,
      page: 0,
    };
    return await refreshGrid(newFilters);
  };

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (isCompleteDateTime(dateTimeFrom) && isCompleteDateTime(dateTimeTo)) {
      const newTimeoutId = setTimeout(() => {
        handleSearchDateTimeChange();
      }, 500); // Delay in ms (500 ms = 0.5 seconds)

      setTimeoutId(newTimeoutId);
    }
  }, [dateTimeFrom, dateTimeTo]);

  const isCompleteDateTime = (date: Date | null) => {
    return date !== null && isValid(date);
  };

  const handleSearchDateTimeChange = async () => {
    const newFilters = {
      ...filters,
      eventTimestampFrom: dateTimeFrom && isValid(dateTimeFrom) && format(dateTimeFrom, "yyyy-MM-dd'T'HH:mm:ss"),
      eventTimestampTo: dateTimeTo && isValid(dateTimeTo) && format(dateTimeTo, "yyyy-MM-dd'T'HH:mm:ss"),
      page: 0,
    } as DataGridFilters;
    await refreshGrid(newFilters);
  };

  const handleWhiteListChange = async () => {
    setWhiteList((prevWhiteList) => {
      const newWhiteList = !prevWhiteList;
      const newFilters = {
        ...filters,
        whiteList: newWhiteList,
        page: 0,
      } as DataGridFilters;
      refreshGrid(newFilters);
      return newWhiteList;
    });
  };

  const refreshGrid = (newFilters: DataGridFilters) => {
    dispatch(fetchTrafficAnalisysRows(newFilters));
    dispatch(setFilters(newFilters));
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
        <Grid container sx={{ mb: 1, gap: { xs: '10px', md: '10px', lg: '5px' }, flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' } }} spacing={0}>
          <Grid item xs={12} md={12} lg={4}>
            <Select inputProps={{ 'aria-label': 'Without label' }} value={selectedLocationLocal} onChange={handleLocationChange} sx={{ width: '100%' }}>
              <MenuItem value="ALL">Wszystkie</MenuItem>
              {allSubLocations.map((l) => (
                <MenuItem key={l.uuid} value={l.uuid}>
                  {l.name.replace('Parking ', '').replace('Wjazd na ', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search">Szukaj...</InputLabel>
              <OutlinedInput
                value={searchText}
                id="outlined-adornment-search"
                onChange={handleSearchChange}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                label="Szukaj"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Stack direction="row" spacing={1}>
              <SortColumn sortBy={sortBy} handleSortByChange={handleSortByChange} items={sortItems} />
              <SortDirection sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1, gap: '5px', flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
          <Grid item xs={12} md={12} lg={4}>
            <Stack direction="column">
              <Typography sx={{ pb: '15px' }}>Data i godzina zdarzenia</Typography>
              <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }}>
                <DateTimePicker
                  label="Od"
                  value={dateTimeFrom}
                  onChange={(fromValue) => {
                    setDateTimeFrom(fromValue);
                  }}
                ></DateTimePicker>
                <DateTimePicker
                  label="Do"
                  value={dateTimeTo}
                  onChange={(toValue) => {
                    setDateTimeTo(toValue);
                  }}
                ></DateTimePicker>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={1.2} sx={{ alignItems: 'center' }}>
            <Stack direction={{ xs: 'row', md: 'row', lg: 'column' }} sx={{ alignItems: 'center' }}>
              <Typography sx={{ pb: { lg: '15px' } }}>Biała lista</Typography>
              <Stack direction="row" sx={{ pt: '5px' }}>
                <Checkbox checked={whiteList} onChange={handleWhiteListChange} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={1} sx={{ alignSelf: 'end' }}>
            <Button
              sx={{
                backgroundColor: '#6868AC',
                color: 'white',
                p: 2,
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: '#5d5d9a',
                },
              }}
              onClick={handleReset}
            >
              reset
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default Filters;

export const buildQueryString = (params: { [key: string]: any }): string => {
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== undefined && value !== null && value !== '' && value !== 'ALL') {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};

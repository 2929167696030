import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, Radio, FormGroup, Stack, FormHelperText, Typography, Backdrop } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { DataGridFilters } from '../../modules/traffic-analisys/models';
import { exportData } from '../../modules/traffic-analisys/operations';
import { format, subDays, subMonths } from 'date-fns';
import { Loader } from '../../components/Icons';

interface ExportDataDialogProps {
  open: boolean;
  onClose: (saved: boolean) => void;
  filters: DataGridFilters;
}

export default function ExportDataDialog({ open, onClose, filters }: ExportDataDialogProps) {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((s) => s.trafficAnalysis.isLoading);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateFromError, setDateFromError] = useState('');
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateToError, setDateToError] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [saveClicked] = useState(false);

  const handleTimePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);

    const today = new Date();
    switch (value) {
      case 'day':
        setDateFrom(today);
        setDateTo(today);
        break;
      case 'week':
        setDateFrom(subDays(today, 7));
        setDateTo(today);
        break;
      case 'month':
        setDateFrom(subMonths(today, 1));
        setDateTo(today);
        break;
      case 'custom':
        setDateFrom(null);
        setDateTo(null);
        break;
      default:
        break;
    }
  };

  const handleDateFromChange = (newValue: Date | null) => {
    setDateFrom(newValue);
  };

  const handleClose = () => {
    clearForm();
    onClose(false);
  };

  const clearForm = () => {
    setSelectedOption('');
    setDateFrom(null);
    setDateFromError('');
    setDateTo(null);
    setDateToError('');
  };

  const handleExportClick = () => {
    const formattedDateFrom = dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null;
    const formattedDateTo = dateTo ? format(dateTo, 'yyyy-MM-dd') : null;
    const updatedFilters = {
      ...filters,
      eventTimestampFrom: formattedDateFrom,
      eventTimestampTo: formattedDateTo,
    } as DataGridFilters;

    dispatch(exportData(updatedFilters));
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Wybierz zakres dat</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Stack direction={'column'} spacing={1}>
              <Stack direction="column" spacing={1}>
                <FormControlLabel control={<Radio onChange={handleTimePeriodChange} checked={selectedOption === 'day'} value="day" name="timePeriod" />} label="dziś" />
              </Stack>
              <Stack direction="column" spacing={1}>
                <FormControlLabel control={<Radio onChange={handleTimePeriodChange} checked={selectedOption === 'week'} value="week" name="timePeriod" />} label="ostatnie 7 dni" />
              </Stack>
              <Stack direction="column" spacing={1}>
                <FormControlLabel control={<Radio onChange={handleTimePeriodChange} checked={selectedOption === 'month'} value="month" name="timePeriod" />} label="ostatni miesiąc" />
              </Stack>
              <Stack direction="column" justifyContent={'left'}>
                <Stack direction="row" alignItems="center" spacing={1} justifyContent={'left'}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
                    <FormControlLabel control={<Radio onChange={handleTimePeriodChange} checked={selectedOption === 'custom'} value="custom" name="timePeriod" />} label="Wybierz własny zakres dat" />
                    <FormControl error={saveClicked && dateFromError !== ''}>
                      <DesktopDatePicker disabled={selectedOption !== 'custom'} label="" value={dateFrom} onChange={handleDateFromChange} />
                      <FormHelperText>{dateFromError}</FormHelperText>
                    </FormControl>

                    <Typography>do:</Typography>
                    <FormControl error={saveClicked && dateToError !== ''}>
                      <DesktopDatePicker disabled={selectedOption !== 'custom'} label="" value={dateTo} onChange={(val) => setDateTo(val)} />
                      <FormHelperText>{dateToError}</FormHelperText>
                    </FormControl>
                  </LocalizationProvider>
                </Stack>
              </Stack>
            </Stack>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Anuluj
          </Button>
          <Button disabled={!dateFrom && !dateTo} variant="contained" onClick={handleExportClick}>
            Pobierz dane
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <Loader />
      </Backdrop>
    </div>
  );
}

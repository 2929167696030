import { alpha, Grid, InputBase, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { getMainLocation, getSubLocations } from '../../modules/location/selector';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { setSelectedLocation } from '../../modules/barriers/slice';
import { fetchBarrierRows } from '../../modules/barriers/operations';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Filters = () => {
  const dispatch = useAppDispatch();
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation.uuid));
  const [selectedLocationLocal, setSelectedLocationLocal] = useState<string>('all');

  const handleLocationChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setSelectedLocationLocal(selectedValue);
    dispatch(fetchBarrierRows(selectedValue));
    dispatch(setSelectedLocation(selectedValue));
  };

  useEffect(() => {
    dispatch(fetchBarrierRows(selectedLocationLocal));
    dispatch(setSelectedLocation(selectedLocationLocal));
  }, [selectedLocationLocal, dispatch]);

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Select inputProps={{ 'aria-label': 'Without label' }} value={selectedLocationLocal} onChange={handleLocationChange} style={{ width: '400px' }}>
            <MenuItem value="all">Wszystkie</MenuItem>
            {allSubLocations.map((l) => (
              <MenuItem key={l.uuid} value={l.uuid}>
                {l.name.replace('Parking ', '').replace('Wjazd na ', '')}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* <Grid item xs={3}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} sx={{ border: '#E2E8F0 solid 1px', borderRadius: '4px', height: 56 }} />
          </Search>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Filters;

import { createSlice } from '@reduxjs/toolkit';
import { AuthStateModel } from './models';
import { logIn, logOut } from './operations';
import Cookies from 'js-cookie';

const user = Cookies.get('user');
const initialState = {
  isAuthenticated: user != undefined
} as AuthStateModel;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    /*loggedIn(state) {
      state.isAuthenticated = true;
    },
    loggedOut(state) {
      state.isAuthenticated = false;
    }*/
  },
  extraReducers(builder) {
    builder.addCase(logIn.fulfilled, (state) => {
      state.isAuthenticated = true;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state.isAuthenticated = false;
    });
  }
});

/*
export const { loggedIn, loggedOut } = authSlice.actions;
*/

export default authSlice.reducer;

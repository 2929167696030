import { Alert, Container, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TopAppBar from './AppBar';
import { getIsAuthenticated } from '../modules/auth/selector';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import MainMenu from './MainMenu';
import theme from './MainTheme';
import { closeInfoBox } from '../modules/layout/slice';
import { getInfoBoxData } from '../modules/layout/selectors';
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './MainMenu.css';

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  const [open, setOpen] = useState(true);

  const dispatch = useAppDispatch();

  let isAuthenticated = useAppSelector((state) => getIsAuthenticated(state.auth));

  const infoBoxData = useAppSelector((s) => getInfoBoxData(s.layout));
  const handleCloseInfoBox = () => {
    dispatch(closeInfoBox());
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {!isAuthenticated && (
          <Container component="main" maxWidth="xs">
            {children}
          </Container>
        )}
        {isAuthenticated && (
          <>
            <TopAppBar />
            <main
              style={
                open
                  ? {
                      position: 'absolute',
                      inset: '0 0 0 0',
                      top: '109px',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      padding: '30px 30px 0 340px',
                    }
                  : {
                      position: 'absolute',
                      inset: '0 0 0 0',
                      top: '109px',
                      left: '-180px',
                      right: '0',
                      bottom: '0',
                      padding: '30px 30px 0 340px',
                    }
              }
              className="TopAppBar"
            >
              {children}
            </main>
            <MainMenu open={open} setOpen={setOpen} />
          </>
        )}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={infoBoxData.open} onClose={handleCloseInfoBox}>
          <Alert severity="success" sx={{ width: '100%' }}>
            {infoBoxData.message}
          </Alert>
        </Snackbar>
        <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={true} newestOnTop={true} draggable pauseOnHover theme="colored" />
      </ThemeProvider>
    </>
  );
};

export default Layout;

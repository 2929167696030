import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataGridFilters, TrafficAnalisysData, TrafficAnalisysStateModel } from './models';
import { exportData, fetchTrafficAnalisysRows } from './operations';

const initialState = {
  filters: { pageSize: 10, pageNo: 0, locationId: 'ALL', sortOrder: 'desc', sortBy: 'eventTimestamp' },
  isLoading: false,
} as TrafficAnalisysStateModel;

const traficAnalisysSlice = createSlice({
  name: 'trafic-analysis',
  initialState: initialState,
  reducers: {
    setFilters(state, action: PayloadAction<DataGridFilters>) {
      state.filters = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTrafficAnalisysRows.fulfilled, (state, action: PayloadAction<TrafficAnalisysData>) => {
      state.data = action.payload;
      state.filters.totalRows = action?.payload?.total;
      state.isLoading = false;
    });
    builder.addCase(fetchTrafficAnalisysRows.pending, (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    });
    builder
      .addCase(exportData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(exportData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setFilters } = traficAnalisysSlice.actions;
export default traficAnalisysSlice.reducer;

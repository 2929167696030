import { Dialog, DialogTitle, DialogContent, FormGroup, Stack, TextField, Typography, Button, DialogActions, Checkbox } from '@mui/material';
import { UserModelProfile } from '../../modules/user/model';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { putUserProfile } from '../../modules/user/operations';
import { getClientProfile } from '../../modules/user/user-selector';
import { logOut } from '../../modules/auth/operations';

export interface DialogProps {
  open: boolean;
  onClose: (saved: boolean) => void;
}

const UserProfileDialog = ({ open, onClose }: DialogProps) => {
  const dispatch = useAppDispatch();
  const actualProfileUser = useAppSelector((state) => getClientProfile(state.user));

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    isValid: false,
  });

  useEffect(() => {
    if (actualProfileUser) {
      setName(actualProfileUser.name);
      setLastName(actualProfileUser.lastName);
      setEmail(actualProfileUser.email);
      setPhone(actualProfileUser.phone);
    }
  }, [actualProfileUser]);

  const handleNameChange = (name: string) => {
    setName(name);
  };

  const handleLastNameChange = (lastName: string) => {
    setLastName(lastName);
  };

  const handlePhoneChange = (phone: string) => {
    setPhone(phone);
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const isPasswordMatch = () => {
    return password === password2;
  };

  const validatePassword = (password: string) => {
    const minLength = password.length >= 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValid = minLength && hasUpperCase && hasSpecialChar;
    return {
      minLength,
      hasUpperCase,
      hasSpecialChar,
      isValid,
    };
  };

  const handlePasswordChange = (password: string, which: 'password1' | 'password2') => {
    setPasswordEntered(true);
    const validation = validatePassword(password);
    if (which === 'password1') {
      setPassword(password);
      setPasswordErrors(validation);
    } else {
      setPassword2(password);
    }
  };

  const handleClose = () => {
    setPassword('');
    setPassword2('');
    setPasswordEntered(false);
    setPasswordChanged(false);
    onClose(false);
  };

  const handleSave = async () => {
    const user = {
      name: name,
      lastName: lastName,
      phone: phone,
      email: email,
      password: password,
    } as UserModelProfile;

    await dispatch(putUserProfile(user));
    if (passwordChanged) {
      await dispatch(logOut());
    }
    onClose(true);
  };

  const Label = styled(Typography)(() => ({
    fontWeight: 600,
  }));

  const isSaveDisabled = passwordEntered ? !passwordErrors.isValid || password !== password2 || !passwordChanged : false;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Edytuj dane użytkownika</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Stack direction={'column'} spacing={1}>
              <Stack direction="column" spacing={1}>
                <Label>Edytuj imię</Label>
                <TextField variant="outlined" value={name} onChange={(e) => handleNameChange(e.target.value)} />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Label>Edytuj nazwisko</Label>
                <TextField variant="outlined" value={lastName} onChange={(e) => handleLastNameChange(e.target.value)} />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Label>Edytuj numer telefonu</Label>
                <TextField variant="outlined" value={phone} onChange={(e) => handlePhoneChange(e.target.value)} />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Label>Edytuj email</Label>
                <TextField variant="outlined" value={email} onChange={(e) => handleEmailChange(e.target.value)} />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Label>Zmień hasło</Label>
                <TextField variant="outlined" value={password} onChange={(e) => handlePasswordChange(e.target.value, 'password1')} />
                {!passwordErrors.minLength && passwordEntered && <Typography color="error">Hasło musi mieć co najmniej 6 znaków.</Typography>}
                {!passwordErrors.hasUpperCase && passwordEntered && <Typography color="error">Hasło musi zawierać co najmniej jedną dużą literę.</Typography>}
                {!passwordErrors.hasSpecialChar && passwordEntered && <Typography color="error">Hasło musi zawierać co najmniej jeden znak specjalny.</Typography>}
                <Label>Powtórz hasło</Label>
                <TextField variant="outlined" value={password2} onChange={(e) => handlePasswordChange(e.target.value, 'password2')} />
              </Stack>
              {passwordEntered && !isPasswordMatch() && <Typography color="error">Hasła muszą być takie same.</Typography>}
              {password === password2 && passwordEntered && passwordErrors.isValid && (
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Label color="error">Czy na pewno chcesz zmieniec hasło</Label>
                  <Checkbox checked={passwordChanged} onChange={() => setPasswordChanged(!passwordChanged)} />
                </Stack>
              )}
            </Stack>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button
            disabled={isSaveDisabled}
            onClick={handleSave}
            sx={{
              px: 3,
              backgroundColor: '#6868AC',
              '&:hover ': { backgroundColor: '#5d5d9a' },
              color: 'white',
            }}
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UserProfileDialog;

import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getClientProfile } from '../modules/user/user-selector';
import { useEffect, useState } from 'react';
import { getUserProfile } from '../modules/user/operations';
import { logOut } from '../modules/auth/operations';
import UserProfileDialog from './user/UserProfileDialog';

const User = () => {
  const dispatch = useAppDispatch();
  const profileUser = useAppSelector((s) => getClientProfile(s.user));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const handleLogout = async () => {
    await dispatch(logOut());
  };

  const openEditDialog = () => {
    setOpen(true);
  };

  const handleOnDialogClose = (saved: boolean) => {
    setOpen(false);
    if (saved) {
      dispatch(getUserProfile());
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <h2>Konto użytkownika</h2>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container>
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ sm: 1, md: 3 }} alignItems="center">
              <Grid item xs={2}>
                <img src="images/user.svg" alt="zdjecie użytkownika" style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={10}>
                <Paper sx={{ borderRadius: '10px', border: '1px solid #E2E8F0', boxShadow: '10px 5px 10px #EFEFEF', padding: '16px 24px', flexGrow: '1' }}>
                  <Grid container spacing={2}>
                    {profileUser?.name && (
                      <Grid item xs={12}>
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '1.28rem' }}>Imię: {profileUser?.name}</Typography>
                      </Grid>
                    )}
                    {profileUser?.lastName && (
                      <Grid item xs={12}>
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '1.28rem' }}>Nazwisko: {profileUser?.lastName}</Typography>
                      </Grid>
                    )}
                    {profileUser?.phone && (
                      <Grid item xs={12}>
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '1.28rem' }}>Telefon: {profileUser?.phone}</Typography>
                      </Grid>
                    )}
                    {profileUser?.email && (
                      <Grid item xs={12}>
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '1.28rem' }}>Email: {profileUser?.email}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Stack direction="row" gap="12px">
                        <Button variant="contained" color="primary" onClick={() => openEditDialog()}>
                          Edytuj dane profilu
                        </Button>

                        <Button variant="contained" color="error" onClick={handleLogout}>
                          Wyloguj
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <UserProfileDialog open={open} onClose={handleOnDialogClose}></UserProfileDialog>
    </>
  );
};

export default User;

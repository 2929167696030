import axios from 'axios';
import { UserStateModel } from '../modules/user/model';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Error occured', error);
    if (error.response.status === 401) {
      console.log('Received 400 error');
      axiosInstance.defaults.headers.common['Authorization'] = '';
      Cookies.remove('user');
      Cookies.remove('structure');
      clearToken();
      window.location.href = '/login';
    }
    return error;
  }
);

if (Cookies.get('user')) {
  const userStr = Cookies.get('user');
  if (userStr) {
    const userStateModel = JSON.parse(userStr) as UserStateModel;

    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + userStateModel?.userModel?.token;
  }
}

export const setToken = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const clearToken = () => {
  axiosInstance.defaults.headers.common['Authorization'] = '';
};

if (process.env.NODE_ENV === 'development') {
  // console.log('Initializing axios mock adapter');
  // initializeMockAdapter(axiosInstance);
}

export default axiosInstance;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { openInfoBox } from '../layout/slice';
import { Barrier, ScheduleData, SendActionData } from './models';
import { getMainLocation, getSubLocations } from '../location/selector';
import { RootState } from '../../store/store';

export const fetchBarrierRows = createAsyncThunk('barriers/fetch', async (locationUuid: string, { getState }): Promise<Barrier[]> => {
  try {
    console.log('fetch barriers ' + locationUuid);
    let rows;

    if (locationUuid === 'all') {
      const state = getState() as RootState;
      const mainLocation = getMainLocation(state.location);
      const allSubLocations = getSubLocations(state.location, mainLocation.uuid);

      const requests = allSubLocations.map((subLocation) => axiosInstance.get(`/v1/location/${subLocation.uuid}/barriers`));

      const results = await Promise.all(requests);
      rows = results.flatMap((result) => result.data);
    } else {
      rows = await axiosInstance.get(`/v1/location/${locationUuid}/barriers`);
      rows = rows.data;
    }

    return rows;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const sendActionBarrier = createAsyncThunk('barriers/open', async (data: SendActionData, thunkAPI): Promise<boolean> => {
  try {
    await axiosInstance.put(`/v1/location/${data.locationUuid}/barriers/${data.barrierUuid}/send-action?action=${data.actionType}`);
    thunkAPI.dispatch(openInfoBox('Komenda została wysłana.'));
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const scheduleBarrierOpening = createAsyncThunk('barriers/schedule', async (data: ScheduleData, thunkAPI): Promise<boolean> => {
  try {
    const body = JSON.stringify({
      openingTime: data.fromTime,
      closingTime: data.toTime,
    });
    await axiosInstance.put(`/v1/location/${data.locationUuid}/barriers/${data.barrierUuid}/schedule`, body);
    thunkAPI.dispatch(openInfoBox('Zapis zakończony sukcesem'));
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

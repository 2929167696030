import ToggleButton from '@mui/material/ToggleButton';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AddIcon from '@mui/icons-material/Add';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

const MapZoom = ({ onClickZoomInit, onClickZoomIn, onClickZoomOut, setMapPitchIn, setMapPitchOut }) => {
  return (
    <ToggleButtonGroup sx={{ position: 'fixed', top: '130px', right: '2vw', backgroundColor: 'white' }} orientation="vertical" exclusive>
      <ToggleButton value="zoom" onClick={onClickZoomInit}>
        <Tooltip title="Rozmiar domyślny">
          <SearchIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="zoomIn" onClick={onClickZoomIn}>
        <Tooltip title="Powiększ mapę">
          <AddIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="zoomOut" onClick={onClickZoomOut}>
        <Tooltip title="Pomniejsz mapę">
          <RemoveIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="pitchIn" onClick={setMapPitchIn}>
        <Tooltip title="Zmniejsz nachylenie mapy">
          <TrendingDownIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="pitchOut" onClick={setMapPitchOut}>
        <Tooltip title="Powiększ nachylenie mapy">
          <TrendingUpIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default MapZoom;

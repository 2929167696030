import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel, UserModelProfile, UserStateModel } from './model';
import { cleanUser, getUserProfile, setUser } from './operations';
import Cookies from 'js-cookie';

const userStateModel = Cookies.get('user') as UserStateModel | undefined;
const initialState = userStateModel
  ? userStateModel
  : ({
      userModel: {
        role: '',
        email: '',
      } as UserModel,
      openDialog: false,
    } as UserStateModel);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserModel>) {
      state.userModel = action.payload;
    },
    openDialog: (state) => {
      state.openDialog = true;
    },
    closeDialog: (state) => {
      state.openDialog = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(setUser.fulfilled, (state, action: PayloadAction<UserModel>) => {
      state.userModel = action.payload;
      Cookies.set('user', JSON.stringify(state), { expires: 1 });
    });
    builder.addCase(cleanUser.fulfilled, (state) => {
      state = {
        userModel: {
          role: '',
          email: '',
        } as UserModel,
      } as UserStateModel;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action: PayloadAction<UserModelProfile | undefined>) => {
      if (action.payload) {
        state.userModelProfile = action.payload;
      }
    });
  },
});

export const { openDialog, closeDialog } = userSlice.actions;
export default userSlice.reducer;

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export interface SortColumnItem {
  value: string;
  name: string;
}

interface SortColumnProps {
  sortBy: string;
  handleSortByChange: (sortByName: string) => void;
  items: SortColumnItem[];
}

const SortColumn = ({ handleSortByChange, sortBy, items }: SortColumnProps) => {
  const handleSortBy = (event: SelectChangeEvent) => {
    handleSortByChange(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="sort-select-label">Sortowanie</InputLabel>
      <Select value={sortBy} onChange={handleSortBy} labelId="sort-select-label" label="Sortowanie">
        {items.map((i, index) => (
          <MenuItem key={index} value={i.value}>
            {i.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortColumn;

import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SalesType, StatBoxType, Statistics, StatisticsData, StatisticsType } from '../modules/location/model';
import { fetchStatistics } from '../modules/location/operations';
import { getMainLocation, getStatistics } from '../modules/location/selector';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import StatisticsBox from './home/StatisticsBox';
import TicketChart from './home/TicketChart';
import React from 'react';

const Home = () => {
  const { location } = useParams<string>();
  const dispatch = useAppDispatch();
  const mainLocation = useAppSelector((s) => getMainLocation(s.location));
  const stats = useAppSelector((s) => getStatistics(s.location));

  const getStatsData = (statType: StatisticsType): Statistics | undefined => {
    if (stats && stats.length > 0) {
      let data = {
        data: {
          exits: 0,
          online: 0,
          onSite: 0,
          entries: 0,
          takenSpots: 0,
          capacity: 0,
          multiDayTickets: 0,
          availableSpots: 0,
          onlineUsed: 0,
          anyMatch: false,
        } as StatisticsData,

        type: {} as StatisticsType,
      } as Statistics;

      stats.forEach((stat) => {
        stat.statistics
          .filter((statsByLocation) => statsByLocation.type === statType)
          .forEach((statsByLocation) => {
            data.type = statsByLocation.type;
            // @ts-ignore
            data.data.exits += statsByLocation.data.exits ?? 0;
            // @ts-ignore
            data.data.online += statsByLocation.data.online ?? 0;
            // @ts-ignore
            data.data.onSite += statsByLocation.data.onSite ?? 0;
            // @ts-ignore
            data.data.entries += statsByLocation.data.entries ?? 0;
            // @ts-ignore
            data.data.takenSpots += statsByLocation.data.takenSpots ?? 0;
            // @ts-ignore
            data.data.capacity += statsByLocation.data.capacity ?? 0;
            // @ts-ignore
            data.data.multiDayTickets += statsByLocation.data.multiDayTickets ?? 0;
            // @ts-ignore
            data.data.availableSpots += statsByLocation.data.availableSpots ?? 0;
            // @ts-ignore
            data.data.onlineUsed += statsByLocation.data.onlineUsed ?? 0;
            // @ts-ignore
            data.data.anyMatch = true;
          });
      });

      if (data) {
        return data;
      }
    }
  };

  const getStatsLocationName = () => {
    if (stats && stats.length) {
      if (stats.length === 1) {
        return stats[0].name;
      }
      return stats.map((x) => x.name.replace('Parking ', '').replace('Wjazd na ', '')).join(', ');
    }
    return '';
  };

  const getSalesData = () => {
    return getStatsData(StatisticsType.TICKET_SALES);
  };
  const getSalesSum = () => {
    const sData = getStatsData(StatisticsType.TICKET_SALES);
    return (sData?.data?.online ?? 0) + (sData?.data?.onSite ?? 0);
  };

  useEffect(() => {
    const fetchStats = () => {
      if (location) {
        dispatch(fetchStatistics(location));
      } else if (mainLocation) {
        dispatch(fetchStatistics(mainLocation.uuid));
      }
    };
    fetchStats();

    const intervalId = setInterval(fetchStats, 60000);

    return () => clearInterval(intervalId);
  }, [location, mainLocation, dispatch]);

  const trafficStat = stats?.find((stat) => stat.statistics.some((s) => s.type === 'TRAFFIC'));
  const whiteListTrafficStat = stats?.find((stat) => stat.statistics.some((s) => s.type === 'WHITE_LIST_TRAFFIC'));

  return (
    <>
      <Grid container spacing={1}>
        {trafficStat || whiteListTrafficStat ? (
          <>
            {trafficStat && (
              <Grid xs={12} xl={6} item>
                <StatisticsBox stats={getStatsData(StatisticsType.TRAFFIC)} circularStats={getStatsData(StatisticsType.CURRENT_CAPACITY)} name={getStatsLocationName()} statType={StatBoxType.Today} />
              </Grid>
            )}
            {whiteListTrafficStat && (
              <Grid xs={12} xl={6} item>
                <StatisticsBox
                  stats={getStatsData(StatisticsType.WHITE_LIST_TRAFFIC)}
                  circularStats={getStatsData(StatisticsType.CURRENT_CAPACITY)}
                  name={getStatsLocationName()}
                  statType={StatBoxType.WhiteList}
                />
              </Grid>
            )}
          </>
        ) : (
          <Typography variant="h6" align="center" sx={{ width: '100%', marginTop: 2 }}>
            {location !== undefined && `Brak statystyk dla tego miejsca`}
          </Typography>
        )}
      </Grid>
      {getStatsData(StatisticsType.TICKET_SALES)?.data.anyMatch ? (
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                border: '1px solid #E2E8F0',
                boxShadow: '10px 5px 10px #EFEFEF',
                borderRadius: '10px',
              }}
            >
              <Grid container>
                <Grid xs={12} item>
                  <Typography sx={{ fontWeight: '800', fontSize: '1.71rem', mb: 1 }}>Bilety parkingowe</Typography>
                </Grid>
                <Grid xs={12} lg={5} item>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.29rem', mb: 1 }}>Sprzedano dzisiaj:</Typography>
                    <Typography sx={{ fontWeight: '800', fontSize: '1.29rem', mb: 1 }}>{(getSalesData()?.data?.online ?? 0) + (getSalesData()?.data?.onSite ?? 0)} szt.</Typography>
                  </Stack>
                </Grid>
                <Grid xs={12} lg={7} item>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.29rem', mb: 1 }}>Wykorzystano na dzisiaj:</Typography>
                    <Typography sx={{ fontWeight: '800', fontSize: '1.29rem', mb: 1 }}>{getSalesData()?.data?.onlineUsed ?? 0} szt.</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: 1 }}>
                <Grid xs={12} md={6} item>
                  <TicketChart salesType={SalesType.Online} value={getSalesData()?.data?.online} sum={getSalesSum()}></TicketChart>
                </Grid>
                <Grid xs={12} md={6} item>
                  <TicketChart salesType={SalesType.Onsite} value={getSalesData()?.data?.onSite} sum={getSalesSum()}></TicketChart>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default Home;

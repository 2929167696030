import { DataGridFilters } from '../modules/traffic-analisys/models';
import { WhiteListRequest } from '../modules/white-list/models';
import { buildQueryString } from './queryStringBuilder';

export const updateWhiteListUrlWithFilters = (filters: WhiteListRequest) => {
  const params = {
    page: (Number(filters.page) + 1).toString(),
    pageSize: filters.pageSize?.toString(),
    locationUuidFilter: filters.locationUuidFilter,
    platesFilter: filters.platesFilter,
    sort: filters.sortBy,
    sortDirection: filters.sortOrder,
    fromDateFilter: filters.fromDateFilter,
    toDateFilter: filters.toDateFilter,
    daysOfWeekFilter: filters.daysOfWeekFilter?.join(','),
    fromHourFilter: filters.fromHourFilter,
    toHourFilter: filters.toHourFilter,
    vehicleTypeUuidFilter: filters.vehicleTypeUuidFilter,
  };

  const queryString = buildQueryString(params);
  const newUrl = `${window.location.pathname}?${queryString}`;
  window.history.pushState({}, '', newUrl);
};

export const updateTrafficAnalysisUrlWithFilters = (filters: DataGridFilters) => {
  const params = {
    page: (Number(filters.pageNo) + 1).toString(),
    pageSize: filters.pageSize?.toString(),
    locationId: filters.locationId,
    searchText: filters.searchText,
    sort: filters.sortBy,
    sortDirection: filters.sortOrder,
    fromDateFilter: filters.eventTimestampFrom,
    toDateFilter: filters.eventTimestampTo,
    whiteList: filters.whiteList ? 'true' : 'false',
  };

  const queryString = buildQueryString(params);
  const newUrl = `${window.location.pathname}?${queryString}`;
  window.history.pushState({}, '', newUrl);
};

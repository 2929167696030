import { useReducer, useEffect } from 'react';
import axiosInstance from '../../utils/axios';

const initialState = {
  URl_STRUCTURE: 'v1/map/entries',
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
  zoom: 12,
  maxZoom: 30,
  minZoom: 10,
  pitch: 60,
  transitionDuration: 500,
  //start point for map
  latitude: 49.2513368,
  longitude: 20.0200782,

  boxZoom: true,
  scrollZoom: true,
  dragRotate: true,
  doubleClickZoom: true,
  touchZoom: true,
  touchRotate: true,

  entries: [],

  pedestrianEntrances: [{ uuid: 'PE0001', name: 'Rozpoczęcie szlaku niebieskiego', latitude: 49.2543134, longitude: 20.1024397 }],

  mountainHostels: [
    { uuid: 'MH001', name: 'Schronisko Morskie Oko', latitude: 49.2013368, longitude: 20.0690782 },
    { uuid: 'MH002', name: 'Schronisko Roztoka', latitude: 49.2337257, longitude: 20.093481 },
  ],

  bounds: [
    [19.90375, 49.19378],
    [20.05375, 49.43378],
  ],
};

const actions = {
  INIT_MAP: 'INIT_MAP',
  ZOOM_INIT: 'ZOOM_INIT',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  ON_MOVE: 'ON_MOVE',
  SET_PITCH: 'SET_PITCH',
};

const reducer = (state, action) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case actions.INIT_MAP:
      return {
        ...state,
        entries: payload.entries,
        //longitude: payload.longitude,
        //latitude: payload.latitude
      };

    case actions.ZOOM_INIT:
      return {
        ...state,
        zoom: payload.zoom,
      };

    case actions.ZOOM_IN:
      return {
        ...state,
        zoom: payload.zoom,
      };

    case actions.ZOOM_OUT:
      return {
        ...state,
        zoom: payload.zoom,
      };

    case actions.ON_MOVE:
      return {
        ...state,
        zoom: payload.viewState.zoom,
        latitude: payload.viewState.latitude,
        longitude: payload.viewState.longitude,
      };

    case actions.SET_PITCH:
      return {
        ...state,
        pitch: payload.pitch,
      };

    default:
      throw new Error('useMap reducer error - action not supported.');
  }
};

const useMap = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initData = async () => {
      const structure = await axiosInstance.get(state.URl_STRUCTURE);
      const entries = structure.data;

      const [mainLocation] = entries; //root element of structure

      const longitude = mainLocation.geoLocation.lot;
      const latitude = mainLocation.geoLocation.lat;

      dispatch({ type: actions.INIT_MAP, payload: { longitude: longitude, latitude: latitude, entries: entries } });
    };

    initData();
  }, []);

  const onClickZoomInit = (event) => {
    const zoom = 12;
    dispatch({ type: actions.ZOOM_INIT, payload: { zoom: zoom } });
  };

  const onClickZoomIn = (event) => {
    const zoom = state.zoom + 0.5;

    dispatch({ type: actions.ZOOM_IN, payload: { zoom: zoom } });
  };

  const onClickZoomOut = (event) => {
    const zoom = state.zoom - 0.5;
    dispatch({ type: actions.ZOOM_OUT, payload: { zoom: zoom } });
  };

  const onMove = (event) => {
    const viewState = event.viewState;
    dispatch({ type: actions.ON_MOVE, payload: { viewState: viewState } });
  };

  const setMapPitchIn = () => {
    const setPitch = state.pitch + 5;
    dispatch({ type: actions.SET_PITCH, payload: { pitch: setPitch } });
  };

  const setMapPitchOut = () => {
    const setPitch = state.pitch - 5;
    dispatch({ type: actions.SET_PITCH, payload: { pitch: setPitch } });
  };

  return {
    ...state,
    onClickZoomInit,
    onClickZoomIn,
    onClickZoomOut,
    onMove,
    setMapPitchIn,
    setMapPitchOut,
  };
};

export default useMap;

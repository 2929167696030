import { Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { DataGridFilters } from '../../modules/traffic-analisys/models';
import { getFilters, getTrafficAnalisysRows, getTrafficAnalysisEnabledColumn } from '../../modules/traffic-analisys/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import format from 'date-fns/format';
import PhotoDialog from './PhotoDialog';
import { useState } from 'react';
import Pagination from '../../components/Pagination';
import { setFilters } from '../../modules/traffic-analisys/slice';
import { fetchTrafficAnalisysRows } from '../../modules/traffic-analisys/operations';

import { VehicleTypeBus, VehicleTypeCar, VehicleTypeMotorcycle, VehicleTypeWheelChair } from '../../components/Icons';
import EventTypeChip from './EventTypeChip';
import ExportDataDialog from './ExportDataDialog';

const DataGrid = () => {
  const dispatch = useAppDispatch();

  const [photoDialogOpen, setPhotoDialogOpen] = useState<boolean>(false);
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const [idPhotoVehicle, setIdPhotoVehicle] = useState<string>('');
  const rows = useAppSelector((s) => getTrafficAnalisysRows(s.trafficAnalysis));

  const enabledColumns = useAppSelector((s) => getTrafficAnalysisEnabledColumn(s.trafficAnalysis));
  const filters = useAppSelector((s) => getFilters(s.trafficAnalysis));

  const handleView = (idVehicle: string) => {
    setIdPhotoVehicle(idVehicle);
    setPhotoDialogOpen(true);
  };
  const handlePageChange = (newPage: number) => {
    const newFilters = { ...filters, pageNo: newPage } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const newFilters = { ...filters, pageSize: pageSize } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const refreshGrid = (newFilters: DataGridFilters) => {
    dispatch(setFilters(newFilters));
    dispatch(fetchTrafficAnalisysRows(newFilters));
  };

  const handleExportView = () => {
    setExportDialogOpen(true);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
        <Table
          aria-label="Analiza ruchu"
          sx={{
            '& th': {
              fontWeight: 800,
            },
          }}
        >
          <TableHead>
            <TableRow>
              {enabledColumns?.includes('vehiclePlates') ? <TableCell>Numer rejestracyjny</TableCell> : null}
              {enabledColumns?.includes('eventType') ? <TableCell>Zdarzenie</TableCell> : null}
              {enabledColumns?.includes('eventTimestamp') ? <TableCell>Data i godzina</TableCell> : null}
              {enabledColumns?.includes('ticketCreationTimestamp') ? <TableCell>Data zakupu biletu</TableCell> : null}
              {enabledColumns?.includes('ticketPaymentTimestamp') ? <TableCell>Płatność</TableCell> : null}
              {enabledColumns?.includes('ticketTypeName') ? <TableCell>Rodzaj biletu</TableCell> : null}
              {enabledColumns?.includes('vehicleType') ? <TableCell>Typ pojazdu</TableCell> : null}
              {enabledColumns?.includes('ticketId') ? <TableCell>Numer biletu</TableCell> : null}
              {enabledColumns?.includes('ticketPaymentLocationName') ? <TableCell>Miejsce zakupu biletu</TableCell> : null}
              {enabledColumns?.includes('ticketPrice') ? <TableCell>Cena biletu</TableCell> : null}
              {enabledColumns?.includes('isWhiteList') ? <TableCell>Biała lista</TableCell> : null}
              {enabledColumns?.includes('image') ? <TableCell>Zdjęcie</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.uuid}>
                {enabledColumns?.includes('vehiclePlates') ? (
                  <TableCell component="th" scope="row">
                    {row.vehiclePlates}
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('eventType') ? (
                  <TableCell>
                    <EventTypeChip eventType={row.eventType}></EventTypeChip>
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('eventTimestamp') ? (
                  <TableCell>
                    <Stack direction="column">
                      <Typography>{format(new Date(row.eventTimestamp), 'dd.MM.yyyy')}</Typography>
                      <Typography>{format(new Date(row.eventTimestamp), 'HH:mm:ss')}</Typography>
                    </Stack>
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('ticketCreationTimestamp') ? (
                  <TableCell>
                    {row.ticketCreationTimestamp === null ? (
                      <></>
                    ) : (
                      <Stack direction="column">
                        <Typography>{format(new Date(row.ticketCreationTimestamp), 'dd.MM.yyyy')}</Typography>
                        <Typography>{format(new Date(row.ticketCreationTimestamp), 'HH:mm:ss')}</Typography>
                      </Stack>
                    )}
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('ticketPaymentTimestamp') ? (
                  <TableCell>
                    {row.ticketPaymentTimestamp === null ? (
                      <></>
                    ) : (
                      <Stack direction="column">
                        <Typography>{format(new Date(row.ticketPaymentTimestamp), 'dd.MM.yyyy')}</Typography>
                        <Typography>{format(new Date(row.ticketPaymentTimestamp), 'HH:mm:ss')}</Typography>
                      </Stack>
                    )}
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('ticketTypeName') ? <TableCell>{row.ticketTypeName}</TableCell> : null}
                {enabledColumns?.includes('vehicleType') ? <TableCell>{getVehicleTypeIconByTicketType(row.ticketTypeId)}</TableCell> : null}
                {enabledColumns?.includes('ticketId') ? <TableCell>{row.ticketId}</TableCell> : null}
                {enabledColumns?.includes('ticketPaymentLocationName') ? <TableCell>{row.ticketPaymentLocationName}</TableCell> : null}
                {enabledColumns?.includes('ticketPrice') ? (
                  <TableCell>
                    {row.ticketPrice.amount} {row.ticketPrice.currency}
                  </TableCell>
                ) : null}
                {enabledColumns?.includes('isWhiteList') ? <TableCell>{row.whiteListEntry ? 'Tak' : 'Nie'}</TableCell> : null}
                {enabledColumns?.includes('image') ? (
                  <TableCell>
                    <Stack direction="row">
                      <Button variant="contained" color="primary" size="small" style={{ marginLeft: 16 }} onClick={() => handleView(row.uuid)}>
                        Zobacz
                      </Button>
                    </Stack>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={12}>
                <Pagination
                  pageNo={filters.pageNo}
                  totalRows={filters.totalRows ?? 0}
                  rowsPerPage={filters.pageSize}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                ></Pagination>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid container sx={{ mt: 1, justifyContent: 'right', marginBottom: '30px' }}>
        <Button variant="contained" onClick={handleExportView}>
          Eksportuj dane
        </Button>
      </Grid>

      <ExportDataDialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)} filters={filters} />
      <PhotoDialog open={photoDialogOpen} base64Photo={idPhotoVehicle} onClose={() => setPhotoDialogOpen(false)}></PhotoDialog>
    </>
  );
};

//https://api.dev.tpn.bergregions.pl/api/internal/v1/traffic-analysis/ticket-types
const getVehicleTypeIconByTicketType = (ticketType: number): any => {
  switch (ticketType) {
    case 13861:
      return VehicleTypeBus();
    case 0:
      return VehicleTypeMotorcycle();
    case 13865:
      return VehicleTypeWheelChair();
    default:
      return VehicleTypeCar();
  }
};

export default DataGrid;

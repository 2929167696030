import { UserModel, UserModelProfile, UserStateModel } from './model';

export const getUser = (state: UserStateModel): UserModel => {
  return state.userModel;
};

export const getClientProfile = (state: UserStateModel): UserModelProfile => {
  return state.userModelProfile;
};

export const getOpenDialog = (state: UserStateModel): boolean => {
  return state.openDialog;
};

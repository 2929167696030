import { EventType } from '../../modules/traffic-analisys/models';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import BlockIcon from '@mui/icons-material/Block';
import { Chip } from '@mui/material';

interface EventTypeChipProps {
  eventType: EventType;
}

const EventTypeChip = ({ eventType }: EventTypeChipProps) => {
  switch (eventType) {
    case EventType.ENTRY:
      return <Chip icon={<LoginIcon />} label='Wjazd' sx={{ backgroundColor: '#C1E1C1' }} size={'small'} />;
    case EventType.EXIT:
      return <Chip icon={<LogoutIcon />} label='Wyjazd' sx={{ backgroundColor: '#FAA0A0' }} size={'small'} />;
    case EventType.ENTRY_ATTEMPT:
      return <Chip icon={<BlockIcon />} label='Próba wjazdu' sx={{ backgroundColor: '#F7DC6F' }} size={'small'} />;
    default:
      return null;
  }
};

export default EventTypeChip;